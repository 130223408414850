var notification = null

/*
 * Class of Notification that handle all Fetching email
 */
class NotificationGet {
    constructor(){
        //singleton
        if (notification) {
            return notification;
        }
        notification = this;
    }

    byId(id, callBack){
        $.ajax({
            url: Routing.generate('getNotificationByAction', {action: 'getOne', opt: id}),
            dataType: 'json',
            success: callBack
        });

    }
}

export default NotificationGet;
