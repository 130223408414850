var config = {};

config.Message = {
    MaximumDisplayedPerPage: 10,
};

config.Admin = {
    checkUIUpdateEveryXSeconds: 60,
}
    
export default config;

