require ('bluebird');
require ('jquery');
require ('jquery-ui-bundle');
require ('jquery-ui-bundle/jquery-ui.min.css');

import config from './config.js';
import NotificationAdmin from './Admin/core/notification.js';
import './Notification/Notification.js';

import './base.css';

/*
 * Common Base of Javascript for the Admin UI
 * Was migrated to frontend too, legacy name
 */
var adminBase = null;
class AdminBase{
    constructor(){
        //singleton
        if(adminBase){
            console.log('Duplicate adminBase returning object');
            return adminBase;
        }

        adminBase = this;

        //this.keepUIUpdated();
        this.displayFlashMessages();

        this.bindEvents();
        this.init();

        return this;
    }

    init(){
        this.bindAutoComplete();
        this.initNotification();
    }

    bindAutoComplete(){
        $.widget( "custom.catcomplete", $.ui.autocomplete, {
            _create: function() {
                this._super();
                this.widget().menu( "option", "items", "> :not(.ui-autocomplete-category)" );
            },
            _renderMenu: function( ul, items ) {
                var that = this,
                    currentCategory = "";
                $.each( items, function( index, item ) {
                    var li;
                    if ( item.category != currentCategory ) {
                        ul.append( "<li class='ui-autocomplete-category'>" + item.category + "</li>" );
                        currentCategory = item.category;
                    }
                    li = that._renderItemData( ul, item );
                    if ( item.category ) {
                        li.attr( "aria-label", item.category + " : " + item.label );
                    }
                });
            }
        });
        $( "#mainSearch" ).catcomplete({
            source: Routing.generate('searchValidProduct'),
            minLength: 2,
            select: function( event, ui ) {
                event.preventDefault();
                window.location.href = ui.item.value;
            }
        });
        $('#mainSearch').keypress(function(event){
            if(event.which == 13){
                event.preventDefault();
                console.log('Enter pressed');
                adminBase.searchEnterPressed(event);
            }
        });

        $( "#mobileSearch" ).catcomplete({
            source: Routing.generate('searchValidProduct'),
            minLength: 2,
            select: function( event, ui ) {
                event.preventDefault();
                window.location.href = ui.item.value;
            }
        });

        $('#btn-main-search').click(function(event){
            event.preventDefault();
            adminBase.searchEnterPressed(event);
        });
    }


    bindEvents(){
        $(document.body).on('click', '.comingSoon', this.showInProgressMessage);

        $(document.body).on('click', '.product-btn-like', this.addToFavorite);
        $(document.body).on('click', '.product-like', this.addToFavorite);
        $(document.body).on('click', '.remove-favorite', this.removeFavorite);
    }

    removeFavorite(event){
        event.preventDefault();
        var id=$(this).data('id');
        var _this = $(this);

        var route = Routing.generate('removeFavorite')+'/'+id;
        $.ajax({
            url: route,
            method: 'POST',
            data: {
                id: id,
            },
            dataType: 'json',
            success: function(msg, data){
                if(msg.success){
                    _this.parent().parent().remove();
                    $('.new-favorite-count').html($('.view-favorite').length);
                }
            }
        });
    }

    addToFavorite(event){
        event.preventDefault();

        var id=$(this).data('id');

        var btn = $(this);
        var route = Routing.generate('toggleFavorite')+'/'+id;

        $('.favorite-icon').addClass('fa-heart').removeClass('fa-heart-o');

        $.ajax({
            url: route,
            method: 'POST',
            data: {
                id: id,
            },
            dataType: 'json',
            success: function(msg, data){
                if(msg.success){
                    /*
                    if(!btn.hasClass('product-like')){
                        if(btn.hasClass('product-btn-liked')){
                            btn.removeClass('product-btn-liked');
                            $(".favorites-listing a").each(function(){
                                if($(this).data('product') == id)
                                    $(this).remove();
                            });
                            $(".favorites-listing a").find(`[data-product='id']`).remove();
                        }else{
                        */
                        if(!btn.hasClass('no-modification')){
                            btn.addClass('product-btn-liked');
                        }
                        $('.favorites-listing').append(msg.html);
                    /*
                        }
                    }
                    */

                    $('.new-favorite-count').html($('.view-favorite').length);
                    setTimeout(function(){
                        $('.favorite-icon').addClass('fa-heart-o').removeClass('fa-heart');
                    }, 1500);
                }
            }
        });
    }

    addToCart(event){
    /*
        event.preventDefault();

        var type=$(this).data('type');
        var id=$(this).data('id');
        var _this = adminBase;

        var quantityWidget = $(this).data('quantity');
        if(quantityWidget){
            var quantity = $('#'+quantityWidget).val();
        }else
            var quantity = 1;

        $.ajax({
            url: Routing.generate('addToCart', {id: id, quantity: quantity}),
            method: 'POST',
            dataType: 'json',
            complete: _this.addedToCart 
        });
    */
    }

    addedToCart(msg){
        $.Notification.autoHideNotify('success','bottom right','Panier', 'Ce produit a été ajouté a votre panier');
        var total = parseInt($('#menu-cart-count').html());
        $('#menu-cart-count').html((total+1));
        $('#menu-cart-count').show();
    }

    searchEnterPressed(event){
        var search = encodeURI($('#mainSearch').val());
        window.location.href = Routing.generate('search.fr', {type: 'a', string: search});
    }

    initNotification(){

    }

    displayFlashMessages(){

        $('#flashMessages').find('.flashMessage').each(function(){
            var type = $(this).data('type');
            if(type == 'focus'){
                var html = $(this).text();
                var targetOffset= $(html).offset().top;
                $('html, body').animate({scrollTop: targetOffset}, 1000);
            }else{
                $.Notification.autoHideNotify($(this).data('type'),'bottom right','Information', $(this).text());
            }
        });
    }

    showInProgressMessage(){
        $.Notification.autoHideNotify('warning','bottom right',Translator.trans('Construction', {}, 'messages'), Translator.trans('This section is under construction', {}, 'messages'));

    }

    keepUIUpdated(){
        setInterval(function(){
            $.ajax({
                url: Routing.generate('getUpdatesForAdminInterface'),
                type: "POST",
                dataType: 'json',
                data: {

                },
                success: function(response){
                    //Start with the notifications
                    if(response.notifications.new){
                        $('.new-notification-count').html(response.notifications.amount).show();
                        $('.new-notification-item').remove();


                        response.notifications.content.forEach(function(element){
                            const newNotification = `
                                <a href="#" class="dropdown-item notify-item view-notification  new-notification-item" data-notifid="${element.id}">
                                <div class="notify-icon ${element.iconColor}"><i class="${element.icon}"></i></div>
                                <p class="notify-details">${element.title}<small class="text-muted">${element.dateSince}</small></p>
                                </a>`;

                            $('.notification-listing').prepend(newNotification);
                        });
                    }else{
                        $('.new-notification-count').html('0').hide();
                        $('.new-notification-item').removeClass('new-notification-item');
                    }
                    //Fin des notifications

                    //Début des messages a mettre a jour
                    if(response.messages.new){
                        $('.new-message-count').html(response.messages.amount).show();
                        $('.new-message-none-menu').hide();

                        //If the user is on the first page
                        if($('#messageShowAmountFrom').html() == '1'){
                            response.messages.content.forEach(function(element){
                                if($("tr").find("[data-id='"+element.id+"']").length == 0){
                                    const newMessage = `
                                        <tr data-open="0" class="trOfAllMessages unread messageReadMessage" data-id="${element.id}">
                                        <td class="">
                                        <div class="checkbox checkbox-primary">
                                        <input type="checkbox" class="messageActionSelected" data-id="${element.id}">
                                        </div>
                                        </td>
                                        <td>
                                        <a href="#" class="email-name">${element.from}</a>
                                        </td>
                                        <td class="">
                                        <a href="#" class="email-msg">${element.subject}</a>
                                        </td>
                                        <td class="hidden-xs">
                                        <a href="#" class="email-msg">${element.message}</a>
                                        </td>
                                        <td class="text-right">${element.dateSent}</td>
                                        </tr>`;
                                    $('#messageListing').prepend(newMessage);
                                };
                            });
                        };
                    }else{
                        $('.new-message-none-menu').show();
                        $('.new-message-count').html('0').hide();
                    }
                }
            });

        }, 15000);
    }
}

/*
 * The loader
 */

/*
 * Once window loaded init the class
 */
$( document ).ready(function() {
    console.log('Admin Base JS app Loaded');

    var notification = new NotificationAdmin();
    notification.init();

    $(".se-pre-con").fadeOut("slow");;
    adminBase = new AdminBase();


});

/* Fix a bug on mobile to add item to cart while page isn't loaded yet */
if(typeof(window.addToCartNotification) != 'undefined' && window.addToCartNotification > 0){
    for (var i = 0; i < window.addToCartNotification; i++) {
        $.Notification.autoHideNotify('success','bottom right','Panier', 'Ce produit a été ajouté a votre panier');
    }
}

$(document.body).on('click', '.add-to-cart', function(){
        $.Notification.autoHideNotify('success','bottom right','Panier', 'Ce produit a été ajouté a votre panier');
});
