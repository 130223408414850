import config from '../config.js';
import NotificationGet from './Get.js';
import NotificationSet from './Set.js';
import NotificationView from './View.js';

import './template.js';

var notification = null

/*
 * Main Notification Class
 */
class NotificationClass {
    constructor(){
        //singleton
        if (notification) {
            return notification;
        }
        notification = this;

        notification.get = new NotificationGet();
        notification.set = new NotificationSet();
        notification.view = new NotificationView();
    }
}

export default NotificationClass;
