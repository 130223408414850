import config from '../../config.js';
import NotificationClass from '../../Notification/Notification.js';

const Custombox = require("exports-loader?Custombox!custombox/dist/custombox.min.js");
global.Custombox = Custombox;
require ('custombox/dist/custombox.min.css');

var notification = null


/*
 * Main Notification Class
 */
class NotificationAdmin extends NotificationClass{
    init(){
        //singleton
        if (notification) {
            return notification;
        }
        notification = this;
        notification.modal = false;

        notification.classButtonView = '.view-notification';
        notification.classButtonCloseNotification = '.closeNotification';

        notification.bindEvents();
    }


    bindEvents() {
        //Binding the Event to something
        $(document.body).on('click', notification.classButtonView, notification.viewNotification);
        $(document.body).on('click', notification.classButtonCloseNotification, notification.closeNotification);
    }

    closeNotification(event){
        event.preventDefault();
        Custombox.modal.close();
    }

    viewNotification(event){
        event.preventDefault();
        var notifId = $(this).data('notifid');
        $(this).css('background-color', 'unset');

        notification.get.byId(notifId, function(msg){
            if(msg){
                $('#modalViewNotificationHeader').html(msg.title);
                $('#modalViewNotificationBody').html(msg.text);

                //We reduce of 1 all new notifications counter
                $('.new-notification-count').each(function(){
                    var got = parseInt($(this).html()) - 1;

                    $(this).html(got);
                    if(got == 0)
                        $(this).hide();
                });

                notification.modal = new Custombox.modal({
                    content: {
                        effect: 'blur',
                        target: '#modalViewNotification'
                    },
                    overlay: {
                        close: false
                    }
                });
                notification.modal.open();
            }

        });
    }
}
export default NotificationAdmin;
