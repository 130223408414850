var notification = null

/*
 * Class of Notification that handle all Fetching email
 */
class NotificationView {
    constructor(){
        //singleton
        if (notification) {
            return notification;
        }
        notification = this;
    }
}

export default NotificationView;
